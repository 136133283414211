import React from "react";
import styled from "styled-components";

const FinalPage = () => {
  return (
    <div>
      <Header>Спасибо за ответы!</Header>
      <Text>Мы уже отправили результаты теста на обработку.</Text>
    </div>
  );
};

const Header = styled.h1`
  margin: 12px 0px 6px 12px;
`;
const Text = styled.div`
  margin: 10px 4px 14px 4px;
  font-size: 16px;
  color: #006699;
`;

export default FinalPage;

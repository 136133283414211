import initialState from "./initialState";

export default function(state = initialState.auth, action) {
  switch (action.type) {
    case "CREATED_USER":
      localStorage.setItem("token", action.token);
      return { is_authed: true, token: action.token };
    case "SUBMITED_ANSWERS":
      localStorage.removeItem("token");
      return { is_authed: false, token: "" };
    default:
      return state;
  }
}

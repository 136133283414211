export default {
  auth: {
    is_authed: !!localStorage.getItem("token"),
    token: localStorage.getItem("token")
  },
  test_content: {
    questions: [],
    answer_variants: []
  },
  userdata: {
    name: "",
    age: "",
    parent_role: ""
  },
  answers: []
};

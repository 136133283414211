import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import styled from "styled-components";

import { fetchAll } from "./actions.js";
import IntroPage from "./containers/IntroPage/IntroPage.js";
import UserInfoMenu from "./containers/UserInfoMenu/UserInfoMenu.js";
import QuestionMenu from "./containers/QuestionMenu/QuestionMenu.js";
import FinalPage from "./containers/FinalPage/FinalPage.js";

class App extends Component {
  state = { is_loaded: false };

  constructor(props) {
    super(props);
    //this.state.is_authed = this.props.is_authed;
  }

  componentDidMount() {
    if (this.props.is_authed)
      fetchAll().then(() => this.setState({ is_loaded: true }));
    else this.setState({ is_loaded: true });
  }

  render() {
    const { is_authed } = this.props;
    const { is_loaded } = this.state;

    if (!is_loaded) return null;

    return (
      <MainCnt>
        <Switch>
          <Route exact path="/:club/" component={IntroPage} />
          <Route path="/userdata/:club/" component={UserInfoMenu} />
          {is_authed ? (
            <Route path="/question/:id/" component={QuestionMenu} />
          ) : (
            <Route path="/end" component={FinalPage} />
          )}
        </Switch>
      </MainCnt>
    );
  }
}

const MainCnt = styled.div`
  margin: 3vh 6vw;
`;

const mapStateToProps = state => ({
  is_authed: state.auth.is_authed
});

export default connect(mapStateToProps)(App);

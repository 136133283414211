import React, { Component } from "react";
import { connect } from "react-redux";
import { Input, Button } from "semantic-ui-react";
import styled from "styled-components";
import { withRouter } from "react-router";

import { newUser, changeUserdata } from "../../actions.js";

class UserInfoMenu extends Component {
  state = {
    is_authed: false,
    name: "",
    age: "",
    parent_role: "",
    is_loading: false
  };

  constructor(props) {
    super(props);

    this.state = {
      is_authed: this.props.is_authed,
      name: this.props.userdata.name.split(" ")[0] || "",
      surname: this.props.userdata.name.split(" ")[1] || "",
      age: this.props.userdata.age,
      parent_role: this.props.userdata.parent_role,
      club: this.props.club
    };
  }

  updateUserdata = async () => {
    const { is_authed } = this.state;
    const data = {
      name: `${this.state.name} ${this.state.surname}`,
      age: this.state.age,
      parent_role: this.state.parent_role,
      club: (this.state.club === "ramenki" ? 'R' : 'B')
    };

    console.log(data);
    this.setState({ is_loading: true });
    let res = {};
    if (!is_authed) res = await newUser(data);
    else res = await changeUserdata(data);

    if (res.status === "ok") this.props.history.push("/question/1");
    else console.log(`Error - ${res.message}`);
    this.setState({ is_loading: true });
  };

  handleInputName(e, data) {
    this.setState({ name: data.value.replace(/\d/g, "") });
  }

  handleInputSurname(e, data) {
    this.setState({ surname: data.value.replace(/\d/g, "") });
  }

  render() {
    const { name, surname, age, parent_role } = this.state;

    return (
      <div>
        <InputName>Как зовут ребёнка</InputName>
        <Input
          fluid
          placeholder="Имя"
          name="name"
          onChange={(e, d) => this.handleInputName(e, d)}
          value={name}
        />
        <Input
          fluid
          placeholder="Фамилия"
          name="surname"
          onChange={(e, d) => this.handleInputSurname(e, d)}
          value={surname}
        />
        <InputName>Возраст</InputName>
        <Input
          fluid
          placeholder="Введите возраст ребёнка"
          onChange={(e, { value }) => {
            this.setState({ age: value });
          }}
          value={age}
        />
        <InputName>Кем вы приходитесь ребёнку</InputName>
        <Input
          fluid
          onChange={(e, { value }) => {
            this.setState({ parent_role: value.replace(/\d/g, "") });
          }}
          value={parent_role}
        />
        <ButtonCnt>
          <Button
            size="large"
            color="pink"
            loading={this.state.is_loading}
            disabled={
              !this.state.name ||
              !this.state.surname ||
              !this.state.age ||
              !this.state.parent_role ||
              this.state.is_loading
            }
            onClick={() => this.updateUserdata()}
          >
            Продолжить
          </Button>
        </ButtonCnt>
      </div>
    );
  }
}

const InputName = styled.div`
  margin: 12px 0px 6px 12px;
  font-size: 18px;
`;
const ButtonCnt = styled.div`
  margin-top: 20px;
  float: right;
`;

const mapStateToProps = (state, ownProps) => ({
  is_authed: state.auth.is_authed,
  userdata: state.userdata,
  club: ownProps.match.params.club
});

export default connect(mapStateToProps)(withRouter(UserInfoMenu));

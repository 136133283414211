import initialState from "./initialState";

export default function(state = initialState.test_content, action) {
  switch (action.type) {
    case "FETCHED_QUESTIONS":
      return {
        questions: action.content.questions,
        answer_variants: action.content.answers
      };
    default:
      return state;
  }
}

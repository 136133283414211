import axios from "axios";

const API_URL = "//misokol.herokuapp.com";
const getHeader = () => {
  const { token } = window.store.getState().auth;
  return token ? { Authorization: `${token}` } : {};
};

const apiGet = url =>
  axios.get(`${API_URL}/test/${url}`, { headers: getHeader() });
const apiPost = (url, data) =>
  axios.post(`${API_URL}/test/${url}`, data, { headers: getHeader() });

export const newUser = async userdata => {
  await apiPost("new_user/", userdata)
    .then(res => {
      window.store.dispatch({
        type: "CREATED_USER",
        token: res.data.token
      });
    })
    .then(fetchAll);

  return { status: "ok" };
};

export const fetchQuestions = async () => {
  const res = await apiGet("questions/");
  window.store.dispatch({
    type: "FETCHED_QUESTIONS",
    content: res.data
  });
};

export const fetchData = async () => {
  const res = await apiGet("get_userdata/");

  if (res.data.status === "ok") {
    window.store.dispatch({
      type: "FETCHED_USERDATA",
      userdata: res.data.userdata,
      answers: res.data.answers
    });
    return { status: "ok" };
  } else return res.data;
};

export const fetchAll = async () => {
  Promise.all([fetchQuestions(), fetchData()]);
};

export const changeUserdata = async new_userdata => {
  const req_data = new_userdata;

  const res = await apiPost("update_userdata/", req_data);
  if (res.data.status === "ok") {
    window.store.dispatch({
      type: "CHANGED_USERDATA",
      new_userdata: res.data.userdata
    });
    return { status: "ok" };
  } else return res.data;
};

export const newAnswer = async answer_data => {
  const req_data = {
    question_number: answer_data.number,
    answer: answer_data.value
  };

  const res = await apiPost("new_answer/", req_data);

  if (res.data.status === "ok") {
    window.store.dispatch({
      type: "NEW_ANSWER",
      answers: res.data.answers
    });
    return { status: "ok" };
  } else return res.data;
};

export const submitAnswers = async () => {
  const res = await apiPost("submit_answers/");

  if (res.status === 200) {
    window.store.dispatch({
      type: "SUBMITED_ANSWERS"
    });
    return { status: "ok" };
  } else return res.data;
};

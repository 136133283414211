import initialState from "./initialState";

export default function(state = initialState.userdata, action) {
  switch (action.type) {
    case "FETCHED_USERDATA":
      return action.userdata;
    case "CHANGED_USERDATA":
      return action.new_userdata;
    case "SUBMITED_ANSWERS":
      return {};
    default:
      return state;
  }
}

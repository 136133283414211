import { combineReducers } from "redux";

import authReducer from "./authReducer";
import contentReducer from "./contentReducer";
import userdataReducer from "./userdataReducer";
import answersReducer from "./answersReducer";

const combinedReducer = combineReducers({
  auth: authReducer,
  test_content: contentReducer,
  userdata: userdataReducer,
  answers: answersReducer
});

const rootReducer = (state, action) => combinedReducer(state, action);

export default rootReducer;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Radio, Button } from "semantic-ui-react";
import _ from "lodash";
import styled from "styled-components";
import { withRouter } from "react-router";

import { newAnswer, submitAnswers } from "../../actions.js";

class QuestionMenu extends Component {
  state = {
    question_id: -1,
    user_answer: -1,
    is_loading: false
  };

  constructor(props) {
    super(props);
    this.state = {
      question_id: this.props.question_id,
      user_answer: this.props.user_answer
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.question_id !== props.question_id ||
        state.user_answer === undefined) {
      return {
        question_id: props.question_id,
        user_answer: props.user_answer
      };
    }
    return null;
  }

  submitAnswer = async () => {
    const { question_id } = this.state;
    const data = {
      number: question_id,
      value: this.state.user_answer
    };

    this.setState({ is_loading: true });

    newAnswer(data)
      .then(() => {
        if (question_id === 80) {
          submitAnswers().then(() => this.props.history.push('/end'));
        }
        else {
          this.props.history.push(`/question/${this.state.question_id + 1}`);
          this.setState({ is_loading: false });
        }
      });
  };

  render() {
    const { questions, answer_variants } = this.props;
    const { question_id, user_answer, is_loading } = this.state;

    return (
      <div>
        <Header>Вопрос №{question_id}</Header>
        <Question>{(question_id === 9 || question_id === 79) ? "Вашего ребёнка" : "Ваш ребёнок"} {questions[question_id]}</Question>

        <Form>
          {_.map(answer_variants, (cur_answer, number) => (
            <Form.Field key={`answer_${number}`}>
              <Radio
                label={cur_answer}
                value={Number(number)}
                checked={Number(number) === user_answer}
                onChange={(e, { value }) => {
                  this.setState({ user_answer: value });
                }}
              />
            </Form.Field>
          ))}
        </Form>

        <ButtonCnt>
          <Button
            size="large"
            color="pink"
            loading={is_loading}
            disabled={user_answer === -1 || is_loading}
            onClick={() => this.submitAnswer()}
          >
            {question_id !== 80 ? "Следующий вопрос" : "Завершить тест"}
          </Button>
        </ButtonCnt>
      </div>
    );
  }
}

const Header = styled.h1`
  margin: 12px 0px 6px 12px;
`;
const Question = styled.div`
  margin: 10px 4px 14px 4px;
  font-size: 16px;
  color: #006699;
`;
const ButtonCnt = styled.div`
  margin-top: 20px;
  float: right;
`;

// May crash if id is higher then amount of questions
const mapStateToProps = (state, ownProps) => ({
  question_id: Number(ownProps.match.params.id),
  questions: state.test_content.questions,
  answer_variants: state.test_content.answer_variants,
  user_answer: state.answers[Number(ownProps.match.params.id) - 1]
});

export default connect(mapStateToProps)(withRouter(QuestionMenu));

import React from "react";
import { Button } from "semantic-ui-react";
import styled from "styled-components";
import { withRouter } from "react-router";

const IntroPage = (props) => {
  return (
    <div>
      <Header>Здравствуйте!</Header>
      <Text>
        С помощью этого теста вы сможете определить ведущие типы характера
        вашего малыша. Вам будет предложено 80 высказываний. Для каждого из них
        дайте оценку — насколько это свойственно вашему малышу или насколько вы
        согласны с этими высказываниями.
      </Text>
      <ButtonCnt>
        <Button
          size="large"
          color="pink"
          onClick={() => props.history.push(`/userdata/${props.match.params.club}/`)}
        >
          Приступить
        </Button>
      </ButtonCnt>
    </div>
  );
};

const Header = styled.h1`
  margin: 12px 0px 6px 12px;
`;
const Text = styled.div`
  margin: 10px 4px 14px 4px;
  font-size: 16px;
  color: #006699;
`;
const ButtonCnt = styled.div`
  margin-top: 20px;
  float: right;
`;

export default withRouter(IntroPage);
